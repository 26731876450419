@use "@delta-defense/delta-utils/styleguide/mixins" as util-mixins;

@mixin is-cabelas-shield {
  .fascia-cabelas &, &.fascia-cabelas {
    @content;
  }
}

a#getNewCode {
  cursor: pointer;
}

.form-text {
  --paragraph-color: var(--color-mono-3);

  @include is-cabelas-shield {
    --paragraph-color: var(--color-mono);
  }

  color: var(--paragraph-color);
}